import React, {Component} from 'react';
import bgImage from '../images/background.png';
import './Main.css';
//import OnScrollChangeColor from './OnScrollChangeColor.js'

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false,
            ContactName: "",
            ContactNumber: "",
            ContactEmail: "",
            ContactMessage: ""
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    inputChanged = (e) => {
        if (e.target.id === "ContactInputName")
            this.setState({ContactName: e.target.value});
        if (e.target.id === "ContactInputPhone")
        {
            const ContactNumber = (e.target.validity.valid) ? e.target.value : this.state.ContactNumber;
            this.setState({ContactNumber: ContactNumber});
        }
        if (e.target.id === "ContactInputEmail")
            this.setState({ContactEmail: e.target.value});
        if (e.target.id === "ContactInputMessage")
            this.setState({ContactMessage: e.target.value});
    }
    
    submitClicked = (e) => {
        // Later in future, add it to db and send email
        console.log(this.state.ContactName);
        console.log(this.state.ContactNumber);
        console.log(this.state.ContactEmail);
        console.log(this.state.ContactMessage);
    }

    render() {
        //const mobileScreen = this.state.mobileScreen;
        //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
        return (
            <div>
                <div style={{maxHeight:"800px", overflow:"hidden"}}>
                    <img src={bgImage} alt="background" height="100%" width="100%" />
                </div>
                <div id={this.state.mobileScreen ? "ContactMobileStyle" : "ContactStyle"}>
                    <p id={this.state.mobileScreen ? "GeneralHeaderFontMobileStyle" : "GeneralHeaderFontStyle"}>GET IN TOUCH</p>
                    <div>
                        <span className="GeneralLabel">Name</span>
                        <br />
                        <input className="GeneralInputs" id="ContactInputName" type="text" maxLength="30" onChange={this.inputChanged.bind(this)} value={this.state.ContactName} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Phone</span>
                        <br />
                        <input className="GeneralInputs" id="ContactInputPhone" type="text" pattern="[0-9]*" maxLength="10" onChange={this.inputChanged.bind(this)} value={this.state.ContactNumber} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Email</span>
                        <br />
                        <input className="GeneralInputs" id="ContactInputEmail" type="text" maxLength="30" onChange={this.inputChanged.bind(this)} value={this.state.ContactEmail} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Message</span>
                        <br />
                        <textarea className="GeneralMessageInputs" id="ContactInputMessage" name="text1" maxLength="100" rows="3" onChange={this.inputChanged.bind(this)} value={this.state.ContactMessage} />
                    </div>
                    <br />
                    <div id="GeneralSubmitButton">
                        <button type="button" className="btn btn-primary" onClick={this.submitClicked.bind(this)}>S U B M I T</button>
                    </div>
                </div>
            </div>
        );
    }
};
export default Contact;