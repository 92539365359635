import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import {CreateNewUserAccount} from './Api';
import './Main.css';
import bgImage from '../images/background.png';
//import OnScrollChangeColor from './OnScrollChangeColor.js'
import MD5 from 'crypto-js/md5';

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false,
            isValidEntry: 0,
            NewAccountInputUserName: "",
            NewAccountInputEmail: "",
            NewAccountInputPassword: "",
            NewAccountInputConfirmPassword: "",
            FinalPassword: "",
            NewAccountInputCode: "",
            isPasswordMatching: true,
            isAllFieldsFilled: true
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    inputChanged = (e) => {
        if (e.target.id === "CreateNewAccountInputUserName")
            this.setState({NewAccountInputUserName: e.target.value});
        if (e.target.id === "CreateNewAccountInputEmail")
            this.setState({NewAccountInputEmail: e.target.value});
        if (e.target.id === "CreateNewAccountInputPassword")
            this.setState({NewAccountInputPassword: e.target.value}, this.checkPasswordMatching);
        if (e.target.id === "CreateNewAccountInputConfirmPassword")
            this.setState({NewAccountInputConfirmPassword: e.target.value}, this.checkPasswordMatching);
        if (e.target.id === "CreateNewAccountInputCode")
            this.setState({NewAccountInputCode: e.target.value});
    }

    checkPasswordMatching = () => {
        if (this.state.NewAccountInputPassword === this.state.NewAccountInputConfirmPassword)
        {
            this.setState({FinalPassword: MD5(this.state.NewAccountInputConfirmPassword).toString()});
            this.setState({isPasswordMatching: true});
        }
        else
            this.setState({isPasswordMatching: false});
    }

    async createClicked() {
        if (this.state.NewAccountInputUserName !== ""
            && this.state.NewAccountInputEmail !== ""
            && this.state.NewAccountInputCode !== ""
            && this.state.isPasswordMatching)
        {            
            var {mobileScreen, isPasswordMatching, isAllFieldsFilled, NewAccountInputPassword, NewAccountInputConfirmPassword, ...other} = this.state;
            this.checkValidEntry(await CreateNewUserAccount(other));
        }
        else
            this.setState({isAllFieldsFilled: false});
    }

    checkValidEntry = (res) => {
        if (!res)
            this.setState({isValidEntry: 1});
        if (res)
            this.setState({isValidEntry: 2});
    }

    render() {
        // If the login entry is invalid, redirect to invalid entry page
        if (this.state.isValidEntry === 1)
            return <Redirect push to={{pathname:"/InvalidNewAccountEntry"}} />;
        if (this.state.isValidEntry === 2)
            return <Redirect push to={{pathname:"/Login"}} />;

        //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
        return (
            <div style={{backgroundColor:"white"}}>
                <div style={{maxHeight:"900px", overflow:"hidden"}}>
                    <img src={bgImage} alt="background" height="100%" width="100%" />
                </div>
                <div id={this.state.mobileScreen ? "PortalDashboardMobileStyle" : "PortalDashboardStyle"}>
                    <p id={this.state.mobileScreen ? "GeneralHeaderFontMobileStyle" : "GeneralHeaderFontStyle"}>New Account</p>
                    <p hidden={this.state.isAllFieldsFilled} style={{color: "red"}}>Please enter all required fields</p>
                    <div>
                        <span className="GeneralLabel">Username</span>
                        <br />
                        <input className="GeneralInputs" id="CreateNewAccountInputUserName" type="text" maxLength="30" onChange={this.inputChanged.bind(this)} value={this.state.NewAccountInputUserName} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Email</span>
                        <br />
                        <input className="GeneralInputs" id="CreateNewAccountInputEmail" type="text" maxLength="30" onChange={this.inputChanged.bind(this)} value={this.state.NewAccountInputEmail} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Password</span>
                        <br />
                        <input className="GeneralInputs" id="CreateNewAccountInputPassword" type="password" onChange={this.inputChanged.bind(this)} value={this.state.NewAccountInputPassword} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Confirm Password</span>
                        <br />
                        <input className="GeneralInputs" id="CreateNewAccountInputConfirmPassword" type="password" onChange={this.inputChanged.bind(this)} value={this.state.NewAccountInputConfirmPassword} />
                    </div>
                    <span hidden={this.state.isPasswordMatching} style={{color: "red"}}>Passwords do not match</span>
                    <br />
                    <div>
                        <span className="GeneralLabel">Code</span>
                        <br />
                        <input className="GeneralInputs" id="CreateNewAccountInputCode" type="text" maxLength="10" onChange={this.inputChanged.bind(this)} value={this.state.NewAccountInputCode} />
                    </div>
                    <span>Please contact Sung for the code</span>
                    <br />
                    <br />
                    <div id="GeneralSubmitButton">
                        <button type="button" className="btn btn-primary" onClick={this.createClicked.bind(this)}>C R E A T E</button>
                    </div>
                    <br />
                    <Link to={{pathname:"/Contact"}}>Need help?</Link>
                </div>
            </div>
        );
    }
};
export default CreateAccount;