import React, {Component} from 'react';
import './Main.css';
import NARLogo from '../images/NARLogo.png';
import EHOLogo from '../images/EHOLogo.png';
import CRMLSLogo from '../images/CRMLSLogo.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen: window.innerWidth <= 750 ? true : false});
    }
    
    render() {
        return (
            <div id="FooterMain">
                <span id={this.state.mobileScreen ? "FooterMobileContent" : "FooterContent"}>Copyright © {new Date().getFullYear()}. Sung Suh. All rights reserved.</span>
                <div>
                    <img src={CRMLSLogo} alt="CRMLSLogo" height="55%" width="5%" />
                    <img src={NARLogo} alt="NARLogo" height="55%" width="3%" />
                    <img src={EHOLogo} alt="EHOLogo" height="55%" width="2%" style={{marginLeft:"3px"}} />
                </div>
            </div>
        );
    }
};
export default Footer;