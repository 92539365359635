import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import About from "./components/About";
import Login from "./components/Login";
import InvalidEntry from "./components/InvalidEntry";
import InvalidNewAccountEntry from "./components/InvalidNewAccountEntry";
import CreateAccount from "./components/CreateAccount";
import PortalDashboard from "./components/PortalDashboard";
import SearchHomes from "./components/SearchHomes";
import RecommendedList from "./components/RecommendedList";
import MySavedList from "./components/MySavedList";
import ViewPropertyDetail from "./components/ViewPropertyDetail";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Navigation />
          <Switch>
            <Route path='/' component={Home} exact />
            <Route path='/About' component={About} exact />
            <Route path='/Login' component={Login} exact />
            <Route path='/InvalidEntry' component={InvalidEntry} exact />
            <Route path='/InvalidNewAccountEntry' component={InvalidNewAccountEntry} exact />
            <Route path='/CreateAccount' component={CreateAccount} exact />
            <Route path='/PortalDashboard' component={PortalDashboard} exact />
            <Route path='/SearchHomes' component={SearchHomes} exact />
            <Route path='/RecommendedList' component={RecommendedList} exact />
            <Route path='/MySavedList' component={MySavedList} exact />
            <Route path='/ViewPropertyDetail' component={ViewPropertyDetail} exact />
            <Route path='/Contact' component={Contact} exact />
            <Route component={Home} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
