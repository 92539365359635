import React, {Component} from 'react';
import {Link} from "react-router-dom";
import './Main.css';
import bgImage from '../images/background.png';
//import OnScrollChangeColor from './OnScrollChangeColor.js';

class InvalidEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    render() {
        //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
        return (
            <div style={{backgroundColor:"white"}}>
                <div style={{maxHeight:"800px", overflow:"hidden"}}>
                    <img src={bgImage} alt="background" height="100%" width="100%" />
                </div>
                <div id={this.state.mobileScreen ? "PortalDashboardMobileStyle" : "PortalDashboardStyle"}>
                <p id={this.state.mobileScreen ? "GeneralHeaderFontMobileStyle" : "GeneralHeaderFontStyle"}>Invalid Entry</p>
                    <p style={{color: "red"}}>Invalid Username or Password</p>
                    <Link to={{pathname:"/Login"}}>Try again</Link>
                    <br />
                    <br />
                    <Link to={{pathname:"/CreateAccount"}}>Create a new account</Link>
                    <br />
                    <br />
                    <Link to={{pathname:"/Contact"}}>Need help?</Link>
                </div>
            </div>
        );
    }
};
export default InvalidEntry;