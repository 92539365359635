import React, {Component} from 'react';
import {Redirect} from "react-router";
import {InsertSearchedProperty} from './Api';
import Cookies from 'universal-cookie';
import './Main.css';
//import OnScrollChangeColor from './OnScrollChangeColor.js'

class SearchHomes extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.state != null)
        {
            this.state = {
                mobileScreen: false,
                scrollable: "no",
                saveStatus: true,
                userID: this.props.location.state.userID,
                token: this.props.location.state.token,
                mlsNumber: ""
            };
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({scrollable: window.innerWidth < 1100 ? "yes" : "no"});
        this.setState({mobileScreen: window.innerWidth <= 750 ? true : false});
    }

    mlsInput = (e) => {
        this.setState({mlsNumber: e.target.value});
    }

    async saveMLSNum() {
        try
        {
            const saveStatus = await InsertSearchedProperty(this.state.token, this.state.userID, this.state.mlsNumber);
            this.setState({saveStatus});
        }
        catch
        {
            this.clearCookie();
        }        
    }

    clearCookie() {
        const cookie = new Cookies();
        cookie.remove("token");
    }

    render() {
        // If the login cookie does not exists, redirect to login page
        var cookieValue = document.cookie.split('=');
        if (this.props.location.state == null || cookieValue[1] == null)
            return <Redirect push to={{pathname:"/Login"}} />; 
        else
        {
            //OnScrollChangeColor(this.state.mobileScreen, "black", "black");
            return (
                <div style={{backgroundColor:"white"}}>
                    <div id={this.state.mobileScreen ? "SearchHomesMobileStyle" : "SearchHomesStyle"}>
                        <div className="form-group" style={{paddingLeft:"60px", paddingRight:"60px"}}>
                            <label style={{color:"#265984", fontSize:"16px", fontWeight:"bold"}}>Enter MLS# to save on your list:</label>
                            <div className="input-group input-group-sm mb-3" style={{verticalAlign:"center"}}>
                                <input id="MLSNumInputs" className="form-control" type="text" maxLength="15" onChange={this.mlsInput.bind(this)} />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="saveMLSNum" data-toggle="modal" data-target=".bd-example-modal-sm" onClick={this.saveMLSNum.bind(this)}>Save</button>
                                </div>
                            </div>
                        </div>
                        <iframe 
                            height="830px"
                            width="100%"
                            position="fixed"
                            title="mls-search"
                            className="embed-responsive-item"
                            frameBorder="0"
                            scrolling={this.state.scrollable}
                            src="https://apexidx.com/idx_lite/advancedsearch/EN_LA/"
                        />
                    </div>
                    {/* Modal */}
                    <div className="modal fade bd-example-modal-sm" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.saveStatus === true ? "Saved" : "You already saved 10 properties"}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
};
export default SearchHomes;