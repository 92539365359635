import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import {SendUserInfo} from './Api';
import Cookies from 'universal-cookie';
import './Main.css';
import bgImage from '../images/background.png';
//import OnScrollChangeColor from './OnScrollChangeColor.js';

class PortalDashboard extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.state != null)
        {
            this.state = {
                mobileScreen: false,
                userID: this.props.location.state.userID,
                token: this.props.location.state.token,
                userInfo: ""
            };
        }
    }

    async componentDidMount() {
        if (this.props.location.state != null)
        {
            try
            {
                const userInfo = await SendUserInfo(this.state.token, this.state.userID);
                this.setState({userInfo});
            }
            catch
            {
                this.clearCookie();
            }
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
        }
    }
    
    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    clearCookie() {
        const cookie = new Cookies();
        cookie.remove("token");
    }

    render() {
        // If the login cookie does not exists, redirect to login page
        var cookieValue = document.cookie.split('=');
        if (this.props.location.state == null || cookieValue[1] == null)
            return <Redirect push to={{pathname:"/Login"}} />; 
        else
        {
            //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
            return (
                <div style={{backgroundColor:"white"}}>
                    <div style={{maxHeight:"800px", overflow:"hidden"}}>
                        <img src={bgImage} alt="background" height="100%" width="100%" />
                    </div>
                    <div id={this.state.mobileScreen ? "PortalDashboardMobileStyle" : "PortalDashboardStyle"}>
                    <p id={this.state.mobileScreen ? "GeneralHeaderFontMobileStyle" : "GeneralHeaderFontStyle"}>Welcome {this.state.userInfo[1]}</p>
                        <p>I would like to:</p>
                        <Link to={{pathname:"/SearchHomes", state:{userID: this.state.userInfo[0], token: this.state.token}}}>Search property</Link>
                        <br />
                        <br />
                        <Link to={{pathname:"/RecommendedList", state:{userID: this.state.userInfo[0], token: this.state.token}}}>View recommended list: {this.state.userInfo[4]} saved</Link>
                        <br />
                        <br />
                        <Link to={{pathname:"/MySavedList", state:{userID: this.state.userInfo[0], token: this.state.token}}}>View my saved list: {this.state.userInfo[3]} saved</Link>
                        <br />
                        <br />
                        {/* <p>Generate amortization table</p>
                        <br />
                        <br /> */}                        
                        <br />
                        <Link to={{pathname:"/Login"}} style={{color: "red"}} onClick={this.clearCookie.bind(this)}>Log Out</Link>
                        <br />
                        <br />
                        <Link to={{pathname:"/Contact"}}>Need help?</Link>
                    </div>
                </div>
            );
        }
    }
};
export default PortalDashboard;