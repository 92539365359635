import React, {Component} from 'react';
import bgImage from '../images/background.png';
import './Main.css';
//import OnScrollChangeColor from './OnScrollChangeColor.js'

class Home extends Component {
    // NEED TO THINK ABOUT MOBILE PAGE!!!!!
    // RIGHT NOW THE PAGE BETWEEN NAV AND FOOTER IS FLOATING... NEED TO FIX IT BETWEEN (ADJUST THE MAIN HEIGHT?)
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false
            //mobileScreen: this.props.location.state
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    render() {
        //const mobileScreen = this.state.mobileScreen;
        //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
        return (
            <div style={{backgroundColor:"black"}}>
                <div style={{maxHeight:"800px", overflow:"hidden"}}>
                    <img src={bgImage} alt="background" height="100%" width="100%" />
                </div>
                {/* <div id="HomeStyle"> */}
                <div id={this.state.mobileScreen ? "HomeMobileStyle" : "HomeStyle"}>
                    <br/>
                    <p className={this.state.mobileScreen ? "HomeMobileLabel" : "HomeLabel"}>Welcome to Sung Suh's personal Real Estate site</p>
                    <br/>
                    <p className={this.state.mobileScreen ? "HomeMobileLabel" : "HomeLabel"}>I can help listing your property or finding your dream home</p>
                    <br/>
                    <p className={this.state.mobileScreen ? "HomeMobileLabel" : "HomeLabel"}>Thank you for your consideration!</p>
                    <br/>
                </div>
            </div>
        );
    }
};
export default Home;