import React, {Component} from 'react';
import './Main.css';
import bgImage from '../images/background.png';
// import aboutImage from '../images/about_test_2.png';
//import OnScrollChangeColor from './OnScrollChangeColor.js'

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    render() {
        //const mobileScreen = this.state.mobileScreen;
        //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
        return (
            <div>
                <div style={{maxHeight:"500px", overflow:"hidden"}}>
                    <img src={bgImage} alt="background" style={{marginTop: "-200px"}} height="100%" width="100%" />
                </div>
                <div id={this.state.mobileScreen ? "AboutMobileStyle" : "AboutStyle"}>
                    {/* <p id="AboutHeaderFontStyle">ABOUT</p> */}
                    {/* <span id={this.state.mobileScreen ? "AboutMobileLabel" : "AboutLabel"}>SUNG SUH</span> */}
                    <span id={this.state.mobileScreen ? "AboutMobileLabel" : "AboutLabel"}>Your Personal Real Estate Agent</span>
                    <div style={{position: "relative", zIndex: "1"}}>
                        {/* <div id="AboutImage">
                            <img src={aboutImage} alt="abouttest" />
                        </div> */}
                        <div id={this.state.mobileScreen ? "AboutMobileSummaryStyle" : "AboutSummaryStyle"}>
                            <span id="AboutSummary">
                            Sung is an active Real Estate Agent at NewStar Realty, based in Southern California's Orange County.
                            <br /><br />
                            Sung is also a member of National Association of REALTORS®.
                            <br /><br />
                            Having grow up in both Orange and Los Angeles County over 20 years, Sung has a vast knowledge of the surrounding cities.
                            <br /><br />
                            Whether it comes down to buying or selling, Sung will prioritize your needs through his dedication and passion for real estate.
                            <br /><br />
                            You can count on Sung to give his full support to you and your property needs as if it were his own.
                            </span>
                        </div>
                    </div>
                    <div id="AboutContact">
                        <span>
                        sungsuhrealtor@gmail.com<br />
                        (310)619-0626<br />
                        DRE# 02109894<br />
                        </span>
                    </div>
                </div>
            </div>
        );
    }
};
export default About;