import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Main.css';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({mobileScreen: window.innerWidth <= 750 ? true : false});
    }

    render() {
        // Check if the device is mobile or not
        //const mobileScreen = this.state.mobileScreen;
        return (
            <div>
                {/* <nav id={mobileScreen ? "navbarMobile" : "navbar"} className="navbar navbar-expand-md navbar-light"> */}
                <nav id="navbar" className="navbar navbar-expand-md navbar-light">
                    {/* <img src={require("../images/logo.png")} alt="logo" /> */}
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <div style={{paddingLeft:50}}><Link to={{pathname:"/Home"}}>HOME</Link></div>
                            <div style={{paddingLeft:50}}><Link to={{pathname:"/About"}}>ABOUT</Link></div>
                            <div style={{paddingLeft:50}}><Link to={{pathname:"/Login"}}>PORTAL</Link></div>
                            <div style={{paddingLeft:50}}><Link to={{pathname:"/Contact"}}>CONTACT</Link></div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
};
export default Navigation;