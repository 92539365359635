const apiUrl = 'https://realtorsungsuh.com:8081/api';
//const apiUrl = 'http://localhost:8081/api';

export async function UserLogin(username, sec)
{
    const res = await fetch(apiUrl + '/username/' + username + '/sec/' + sec);
    const json = await res.json();
    return json;
}

export async function SendUserInfo(token, opt)
{
    const res = await fetch(apiUrl + '/userid/' + opt, {
        headers: {"Token": token},
    });
    const json = await res.json();
    return json;    
}

export async function CreateNewUserAccount(state)
{
    const res = await fetch(apiUrl + '/newuser', {
        headers: {"Token": JSON.stringify(state),}
    });
    const json = await res.json();
    return json;
}

export async function SendMySavedPropertyList(token, opt)
{
    const res = await fetch(apiUrl + '/mysaved_userid/' + opt, {
        headers: {"Token": token},
    });
    const json = await res.json();
    return json;
}

export async function SendRecommendedPropertyList(token, opt)
{
    const res = await fetch(apiUrl + '/recommended_userid/' + opt, {
        headers: {"Token": token},
    });
    const json = await res.json();
    return json;
}

export async function InsertSearchedProperty(token, userId, mlsNumber)
{
    const res = await fetch(apiUrl + '/saveproperty_userid/' + userId + '/saveproperty_mlsnum/' + mlsNumber, {
        headers: {"Token": token},
    });
    const json = await res.json();
    return json;
}

export async function DeleteSavedProperty(token, userId, mlsNumber)
{
    await fetch(apiUrl + '/deleteproperty_userid/' + userId + '/deleteproperty_mlsnum/' + mlsNumber, {
        headers: {"Token": token},
    });
}