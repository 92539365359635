function OnScrollChangeColor(mobileScreen, color1, color2)
{
    // Navigation Bar on Scroll
    window.onscroll = function() {scrollChangeColor(mobileScreen, color1, color2)};            
    function scrollChangeColor(mobileScreen, color1, color2)
    {
        // Change the color when the page scrolls down
        if (!mobileScreen)
        {
            if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40)
                document.getElementById("navbar").style.backgroundColor = color1;
            else
                document.getElementById("navbar").style.backgroundColor = color2;
        }
        // if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40)
        //     mobileScreen ? document.getElementById("navbarMobile").style.backgroundColor = "white" : document.getElementById("navbar").style.backgroundColor = color1;
        // else
        //     mobileScreen ? document.getElementById("navbarMobile").style.backgroundColor = "white" : document.getElementById("navbar").style.backgroundColor = color2;
    }
}
export default OnScrollChangeColor;