import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import {SendRecommendedPropertyList} from './Api';
import Cookies from 'universal-cookie';
import './Main.css';
//import OnScrollChangeColor from './OnScrollChangeColor.js'

class RecommendedList extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.state != null)
        {
            this.state = {
                mobileScreen: false,
                userID: this.props.location.state.userID,
                token: this.props.location.state.token,
                recommendedHomesInfo: ""
            };
        }
    }

    async componentDidMount() {
        if (this.props.location.state != null)
        {
            try
            {
                const recommendedHomesInfo = await SendRecommendedPropertyList(this.state.token, this.state.userID);
                this.setState({recommendedHomesInfo});
            }
            catch
            {
                this.clearCookie();
            }
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
        }
    }

    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    clearCookie() {
        const cookie = new Cookies();
        cookie.remove("token");
    }

    showTableRow = (recommendedHomesInfo) => {
        var obj = JSON.parse(recommendedHomesInfo)
        return (
            obj.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{String.prototype.trim.call(item.mlsnumber)}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.msText)}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.addre + item.zipCode.substring(0, 5))}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call("$" + ((item.maxPrice / 1000) > 1 ? (item.maxPrice / 1000) + "K" : item.maxPrice))}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.beds)}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.baths)}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.yearBuilt)}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.sqFt)}</td>
                        <td className="d-none d-sm-table-cell">{String.prototype.trim.call(item.lotSqft)}</td>
                        {/* View should have link to show detail website */}
                        <td><Link to={{pathname:"/ViewPropertyDetail", state: {mobileScreen: this.state.mobileScreen, mlsNumber: item.mlsnumber}}}>View</Link></td>
                    </tr>
                );
            })
        );
    }

    render() {
        // If the login cookie does not exists, redirect to login page
        var cookieValue = document.cookie.split('=');
        if (this.props.location.state == null || cookieValue[1] == null)
            return <Redirect push to={{pathname:"/Login"}} />; 
        else
        {
            //OnScrollChangeColor(this.state.mobileScreen, "black", "black");
            return (
                <div style={{backgroundColor:"white"}}>
                    <div id={this.state.mobileScreen ? "SearchHomesMobileStyle" : "SearchHomesStyle"}>
                        <p>Showing recommended homes</p>
                        <div className="table-responsive">
                            <table className="table table-hover table-sm">
                                <thead style={{color:'#FFFFFF',backgroundColor:'#505050'}}>
                                    <tr>
                                        <th scope="col" style={{width:'80px'}}>MLS#</th>
                                        <th scope="col" style={{width:'80px'}} className="d-none d-sm-table-cell">Status</th>
                                        <th scope="col" style={{width:'200px'}} className="d-none d-sm-table-cell">Address</th>
                                        <th scope="col" style={{width:'70px'}} className="d-none d-sm-table-cell">Price</th>
                                        <th scope="col" style={{width:'60px'}} className="d-none d-sm-table-cell">Bed</th>
                                        <th scope="col" style={{width:'60px'}} className="d-none d-sm-table-cell">Bath</th>
                                        <th scope="col" style={{width:'80px'}} className="d-none d-sm-table-cell">Year Built</th>
                                        <th scope="col" style={{width:'80px'}} className="d-none d-sm-table-cell">Sqft</th>
                                        <th scope="col" style={{width:'80px'}} className="d-none d-sm-table-cell">Lot Sqft</th>
                                        <th scope="col" style={{width:'95px'}}>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.recommendedHomesInfo.length > 0 ? this.showTableRow(this.state.recommendedHomesInfo) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }
};
export default RecommendedList;