import React, {Component} from 'react';
import {Redirect} from "react-router";
import './Main.css';
import OnScrollChangeColor from './OnScrollChangeColor.js'

class ViewPropertyDetail extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.state != null)
        {
            this.state = {
                mobileScreen: false,
                scrollable: "no",
                mlsNumber: this.props.location.state.mlsNumber
            };
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({scrollable: window.innerWidth < 1100 ? "yes" : "no"});
        this.setState({mobileScreen: window.innerWidth <= 750 ? true : false});
    }

    render() {
        // If the login cookie does not exists, redirect to login page
        var cookieValue = document.cookie.split('=');
        if (this.props.location.state == null || cookieValue[1] == null)
            return <Redirect push to={{pathname:"/Login"}} />; 
        else
        {
            const url = "https://apexidx.com/idx_lite/results/EN_LA/" + this.state.mlsNumber + "_mlsNumber/price_orderBy/asc_order"
            OnScrollChangeColor(this.state.mobileScreen, "black", "black");
            console.log(this.state.mlsNumber);
            return (
                <div style={{backgroundColor:"white"}}>
                    <div id={this.state.mobileScreen ? "SearchHomesMobileStyle" : "SearchHomesStyle"}>
                        <iframe 
                            height="830px"
                            width="100%"
                            position="fixed"
                            title="mls-search"
                            className="embed-responsive-item"
                            frameBorder="0"
                            scrolling={this.state.scrollable}
                            src={url}
                        />
                    </div>
                </div>
            );
        }
    }
};
export default ViewPropertyDetail;