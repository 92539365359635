import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import MD5 from 'crypto-js/md5';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import {UserLogin} from './Api';
import './Main.css';
import bgImage from '../images/background.png';
//import OnScrollChangeColor from './OnScrollChangeColor.js'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileScreen: false,
            username: "",
            sec: "",
            userID: "",
            token: "",
            redirect: false,
            isInvalidCredential: false
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileScreen:window.innerWidth <= 750 ? true : false});
    }

    usernameInput = (e) => {
        this.setState({username: e.target.value})
    }

    passwordInput = (e) => {
        var pwd = MD5(e.target.value).toString();
        this.setState({sec: pwd});
    }

    async loginClicked() {
        const userID = await UserLogin(this.state.username, this.state.sec);
        if (userID != null && userID !== "")
        {
            // Create cookie to store JWT
            const cookies = new Cookies();
            var oneHourLater = new Date();
            oneHourLater.setMinutes(oneHourLater.getMinutes() + 60);
            cookies.set("token", userID["access_token"], {path: '/', expires: oneHourLater});
            
            // Decode the token to get the User ID
            var token = jwt_decode(userID["access_token"]);
            this.setState({userID: token["userid"]});
            if (this.state.userID.length > 0)
            {
                this.setState({token: userID["access_token"]});
                this.setState({redirect: true});
            }
        }
        else
            this.setState({isInvalidCredential: true});
    }

    render() {
        // If the login cookie exists, redirect to landing page
        var cookieValue = document.cookie.split('=');
        if (cookieValue[1] != null)
        {
            var getUserId = jwt_decode(cookieValue[1]);
            var userID = getUserId["userid"];
            return <Redirect push to={{pathname:"/PortalDashboard", state: {userID: userID, token: cookieValue[1]}}} />; 
        }
        if (this.state.redirect)
            return <Redirect push to={{pathname:"/PortalDashboard", state: {userID: this.state.userID, token: this.state.token}}} />;

        // If the login entry is invalid, redirect to invalid entry page
        if (this.state.isInvalidCredential)
            return <Redirect push to={{pathname:"/InvalidEntry"}} />;
        
        // Allows Enter key to submit information
        const handleKeyPress = (e) => {
            if (e.key === 'Enter')
                this.loginClicked();
        }

        //OnScrollChangeColor(this.state.mobileScreen, "black", "transparent");
        return (
            <div style={{backgroundColor:"white"}}>
                <div style={{maxHeight:"800px", overflow:"hidden"}}>
                    <img src={bgImage} alt="background" height="100%" width="100%" />
                </div>
                <div id={this.state.mobileScreen ? "PortalDashboardMobileStyle" : "PortalDashboardStyle"}>
                    <p id={this.state.mobileScreen ? "GeneralHeaderFontMobileStyle" : "GeneralHeaderFontStyle"}>Portal Login</p>
                    <div>
                        <span className="GeneralLabel">Username</span>
                        <br />
                        <input className="GeneralInputs" id="LoginInputUsername" type="text" maxLength="30" onChange={this.usernameInput.bind(this)} onKeyPress={handleKeyPress} />
                    </div>
                    <br />
                    <div>
                        <span className="GeneralLabel">Password</span>
                        <br />
                        <input className="GeneralInputs" id="LoginInputSec" type="password" onChange={this.passwordInput.bind(this)} onKeyPress={handleKeyPress} />
                    </div>
                    <br />
                    <div id="GeneralSubmitButton">
                        <button type="button" className="btn btn-primary" onClick={this.loginClicked.bind(this)}>L O G I N</button>
                    </div>
                    <br />
                    <br />
                    <Link to={{pathname:"/CreateAccount"}}>Create a new account</Link>
                    <br />
                    <br />
                    <Link to={{pathname:"/Contact"}}>Need help?</Link>
                </div>
            </div>
        );
    }
};
export default Login;